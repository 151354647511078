/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * See: https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
 */

import React from 'react'

// import App from 'App'

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import 'assets/scss/app.scss'

const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {element}
    </>
  )
}

// 'wrapPageElement' must be a named export
export { wrapPageElement }
