// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-form-template-js": () => import("./../src/templates/FormTemplate.js" /* webpackChunkName: "component---src-templates-form-template-js" */),
  "component---src-templates-project-template-js": () => import("./../src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-old-js": () => import("./../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */)
}

